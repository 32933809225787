<template>
  <div class="row">    
    <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1" style="margin-bottom: 10px">
    <div class="pull-right">
      <b-button @click="add" class="btn btn-success" v-if="showForAdmin()">{{ $t("common.new") }}</b-button>
    </div>
  </div>
    <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
      <table class="table table-bordered">
        <thead class="thead-light">
        <tr>
          <th class="text-left">
            Преподаватель
          </th>
          <th class="text-left">
            Дата с
          </th>
          <th class="text-left">
            Дата по
          </th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in items" :key="item.id">
          <td>{{ item.teacherName }}</td>
          <td>{{ UtilsService.formatDate(item.dateFrom) }}</td>
          <td>{{ UtilsService.formatDate(item.dateTo) }}</td>
          <td>
            <b-button
                type="submit"
                @click="editItem(item)"
                variant="primary"
                v-if="showForAdmin()"
            >
              {{ $t("common.edit") }}
            </b-button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <b-modal ref="SubstituteTeacherAdd-modal">
      <template v-slot:modal-title>
        {{ $t("common.new") }}
      </template>
      <b-form>
        <b-form-group>
          <template v-slot:label>
            Преподаватель
          </template>
          <b-form-select
              v-model="form.teacherId"
              :options="teachers"
              :state="substituteTeacherModelState.teacherId"
          ></b-form-select>
          <div class="invalid-feedback">{{ substituteTeacherModelSaveError.teacherId }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            С 
          </template>
          <b-form-datepicker
              v-model="form.dateFrom"
              locale="ru"
              start-weekday="1"
              :state="substituteTeacherModelState.dateFrom"
          ></b-form-datepicker>
          <div class="invalid-feedback">{{ substituteTeacherModelSaveError.dateFrom }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            По
          </template>
          <b-form-datepicker
              v-model="form.dateTo"
              locale="ru"
              start-weekday="1"
              :state="substituteTeacherModelState.dateTo"
          ></b-form-datepicker>
          <div class="invalid-feedback">{{ substituteTeacherModelSaveError.dateTo }}</div>
        </b-form-group>
        
      </b-form>
      <template v-slot:modal-footer>
        <b-button type="submit" @click="onSubmit" variant="primary">{{
            $t("common.save")
          }}
        </b-button>
        <b-button type="reset" @click="onReset" variant="danger">{{
            $t("common.cancel")
          }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<style>
.dropped td {
  background-color: #F64E60;
}
</style>
<script>
import ApiService from "@/common/api.service";
import UtilsService from "@/common/utils.service";
import {mapGetters} from "vuex";

export default {
  name: "SubstituteTeacher",
  components: {    
  },
  props: {
    groupSubjectId: String,
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "currentUser"
    ])
  },
  data() {
    return {
      items: [],
      form: {
        dateFrom: null,
        dateTo: null,
        teacherId: null
      },
      substituteTeacherModelState:{},
      substituteTeacherModelSaveError:{},
      teachers:[]
    };
  },
  mounted() {
    ApiService.querySecured("dictionaries/teachersLoggedUserSchool", {}).then(({data}) => {
      this.teachers = data.dictionary;
    }).catch(({response}) => {
      console.log(response);
    });
    
    this.reloadData();
  },
  setup() {
    return { UtilsService }
  },
  methods: {
    reloadData() {
      ApiService.querySecured("groupSubjects/substituteTeacherList", {
        params: {groupSubjectId: this.groupSubjectId}
      }).then(({data}) => {
        this.items = data;
      }).catch(({response}) => {
        console.log(response);
      });
      
      
    },
    onSubmit(evt) {
      evt.preventDefault();
      let $this = this;
      this.form.subjectId = this.groupSubjectId;

      this.eduPlanItemModelState = {};
      this.eduPlanItemModelSaveError = {};

      ApiService.postSecured("groupSubjects/saveSubstituteTeacher", this.form)
          .then(function () {
            $this.$refs["SubstituteTeacherAdd-modal"].hide();
            $this.reloadData();
            $this.cleanModel();
          })
          .catch(({response}) => {
            if (response.data != null && response.data.errors != null) {
              $this.substituteTeacherModelSaveError = response.data.errors;

              for (let i in response.data.errors) {

                $this.substituteTeacherModelState[i] = false;
              }
            }
          });
    },
    add() {
      this.form = {};
      this.$refs["SubstituteTeacherAdd-modal"].show();
    },
    editItem(item){
      this.form = item;
      this.$refs["SubstituteTeacherAdd-modal"].show();
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {};

      this.$refs["SubstituteTeacherAdd-modal"].hide();
    },
    showForAdmin(){
      return this.currentUser.role == 'ROLE_SCHOOL_ADMIN';
    },
  }
};
</script>
